<!--
    This component shows a calendar and clock icon which upon click shows an overlay
    to select either a date or a time, which will then be passed onto the string of the value prop

    If the value prop is modified outside of the component, it will be watched for changed, and the correct
    date/time in the picker(s) will be selected.
-->
<template>
  <VDropdown
    :triggers="[]"
    :shown="anyPickerShown"
    :autoHide="true"
    :distance="6"
    placement='bottom-end'
    @auto-hide="closeAllPickers"
  >
    <fa-icon
      v-if="!disabled"
      class="text-sm text-gray-400 hover:text-gray-500 cursor-pointer"
      fixed-width
      icon="calendar"
      @click="toggleDatePicker"
    >
    </fa-icon>
    <fa-icon
      v-if="withTime && !disabled"
      :class="[dateTimeModel === null ? 'cursor-not-allowed' : 'hover:text-gray-500 cursor-pointer']"
      class="text-gray-400 mt-0.5 text-sm"
      fixed-width
      icon="clock"
      @click="toggleTimePicker"
    />

    <template #popper>
      <div>
        <div
          v-if="showDatePicker"
        >
          <DatePicker
            ref="datePicker"
            v-model="dateFromPicker"
            :disabled-dates="getDisabledDates"
            :language="fr"
            :year-picker-range="12"
            class="text-sm"
            first-day-of-week="mon"
            inline
            @input="onDateSelectedInPicker"
          />

          <div class="w-full bg-white p-2 border-l border-b border-r border-gray-300 flex justify-end gap-x-2">
            <button-element
              size="small"
              @click="setToToday"
            >
              Aujourd'hui
            </button-element>

            <button-element
              v-if="withTime && !isUsedForRange"
              size="small"
              type="light"
              @click="setToNow"
            >
              Maintenant
            </button-element>
          </div>
        </div>

        <div
          v-if="showTimePicker"
          class="bg-white border border-gray-400 p-2"
        >
          <div class="flex gap-x-1">
            <input
              ref="hourInput"
              v-model="timeFromPicker.hours"
              class="w-12"
              max="23"
              min="0"
              placeholder="HH"
              type="number"
              @input="updateTime"
            >
            :
            <input
              ref="minuteInput"
              v-model="timeFromPicker.minutes"
              class="w-12"
              max="59"
              min="0"
              placeholder="mm"
              type="number"
              @input="updateTime"
            >
          </div>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker'
import '@sum.cumo/vue-datepicker/dist/Datepicker.css'
import {fr} from '@sum.cumo/vue-datepicker/dist//locale/index.esm';
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";

export default {
  name: "DateTimePickerIconsWithOverlay",
  components: {ButtonElement, DatePicker},
  props: {
    value: {
      type: String
    },
    withTime: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * When a date is selected on the picker,
     * the time will be set by default to these values
     */
    onDateSelection: {
      type: Object,
      default: () => ({
        setTime: {
          hour: 0,
          minute: 0,
          second: 0
        }
      })
    },
    disallowPastDate: {
      type: Boolean,
      default: false
    },
    isUsedForRange: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.setDateOnPicker(this.dateTimeModel);
    this.convertDateFormat();
  },
  data() {
    return {
      fr: fr,

      showDatePicker: false,
      showTimePicker: false,

      dateFromPicker: new Date(),
      timeFromPicker: {
        hours: 0,
        minutes: 0
      }
    }
  },
  watch: {
    dateTimeModel(value) {
      this.setDateOnPicker(value);

      // If the date is changed to Server format (YYYY-MM-DD),
      // which usually only happens outside of the component
      // The Date will be converted to Readable Format.
      // NOTE: This will cause typing annoyances when typing in server format.
      this.convertDateFormat();
    },
  },
  computed: {
    dateTimeModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    /**
     * Determines if any picker is shown currently.
     * @returns {boolean}
     */
    anyPickerShown() {
      return this.showDatePicker || this.showTimePicker;
    },

    getDisabledDates() {
      if (this.disallowPastDate) {
        let date = new Date();
        date.setDate(date.getDate() - 1);

        return {
          to: date
        }
      }
      return null;
    }

  },

  methods: {
    /**
     * Opens or closes the date picker menu.
     * @returns {boolean}
     */
    toggleDatePicker() {
      if (this.disabled) {
        return false;
      }

      this.showDatePicker = !this.showDatePicker;
      if (this.showDatePicker) {
        this.showTimePicker = false;
      }
    },

    closeAllPickers() {
      this.showDatePicker = false;
      this.showTimePicker = false;
    },

    /**
     * Opens or closes the time picker menu.
     * @returns {boolean}
     */
    toggleTimePicker() {
      
      if (this.disabled) {
        return false;
      }

      // Date needs to be selected first
      if (this.dateTimeModel === null) {
        return false;
      }

      this.showTimePicker = !this.showTimePicker;
      if (this.showTimePicker) {
        this.showDatePicker = false;
        this.$nextTick(() => this.$refs.hourInput.focus());
      }
    },

    /**
     * Updates the Date selected in the picker when a date is entered manually.
     * @param date
     * @returns {boolean}
     */
    setDateOnPicker(date) {
      // if no date set, set default picker preselection to today.
      if (date === null) {
        this.dateFromPicker = this.$date().toDate();
        this.timeFromPicker.hours = 0;
        this.timeFromPicker.minutes = 0;
        return;
      }


      let dayJsDate = this.getStringDateAsDayJSDate(date, true);
      if (!dayJsDate) {
        dayJsDate = this.getStringDateAsDayJSDate(date, false);
      }

      if (dayJsDate) {
        this.dateFromPicker = dayJsDate.toDate();
        this.timeFromPicker.hours = dayJsDate.hour();
        this.timeFromPicker.minutes = dayJsDate.minute();
      }
    },
    /**
     * Updates the form field value when the user selects a date in the picker/calendar.
     * @param date
     * @param forceNow
     */
    onDateSelectedInPicker(date, forceNow = false) {
      let dayJsDate = this.$date(date);

      if (!forceNow) {
        dayJsDate = dayJsDate
          .hour(this.onDateSelection.setTime.hour)
          .minute(this.onDateSelection.setTime.minute)
          .second(this.onDateSelection.setTime.second);
      }

      this.dateTimeModel = dayJsDate.format(this.getReadableFormat(this.withTime));
      this.showDatePicker = false;
      this.$nextTick(() => {
        this.$emit('selected');
      })
    },

    /**
     * Sets the selected date to the current day.
     */
    setToToday() {
      this.onDateSelectedInPicker(new Date());
    },

    /**
     * Sets the selected date to the current day.
     */
    setToNow() {
      this.onDateSelectedInPicker(new Date(), true);
    },
    /**
     * Called when the user sets the Time from the Time Overlay menu
     */
    updateTime() {
      let dayJsDate = this.$date(this.dateTimeModel, this.getReadableFormat(this.withTime));
      dayJsDate = dayJsDate.hour(this.timeFromPicker.hours).minute(this.timeFromPicker.minutes);
      this.dateTimeModel = dayJsDate.format(this.getReadableFormat(this.withTime));
    },

    /**
     * Converts a date in string format to a Day.js Date
     * @param date
     * @param withTime
     * @returns {*|null}
     */
    getStringDateAsDayJSDate(date, withTime) {
      let dayJsDate = this.$date(date, [this.getReadableFormat(withTime), this.getServerFormat(withTime)]);
      if (dayJsDate.isValid()) {
        return dayJsDate;
      }

      return null;
    },

    /**
     * Converts YYYY-MM-DD to DD.MM.YYYY
     */
    convertDateFormat() {
      let date = this.$date(this.dateTimeModel, [this.getServerFormat(this.withTime)], true);
      if (date.isValid()) {
        this.dateTimeModel = date.format(this.getReadableFormat(this.withTime));
      }
    },


    /**
     * Returns the format in which we want to display the date to the user.
     * @returns {string}
     */
    getReadableFormat(withTime) {
      if (withTime) {
        return "DD.MM.YYYY HH:mm:ss";
      }
      return "DD.MM.YYYY";
    },

    /**
     * Returns the format the server usually returns the date in.
     * @returns {string}
     */
    getServerFormat(withTime) {
      if (withTime) {
        return "YYYY-MM-DD HH:mm:ss";
      }
      return "YYYY-MM-DD";
    },
  },
}
</script>

<style scoped>

</style>