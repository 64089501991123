<template>
  <div>
    <div class="flex gap-x-2">
      <button-element
        v-for="size in pageSizes"
        :key="size"
        :type="getItemsPerPage === size ? 'primary' : 'light'"
        size="small"
        @click="setItemsPerPage(size)"
      >
            <span class="text-sm">
                {{ size }}
            </span>
      </button-element>
    </div>
    <div class="text-sm mt-3 text-gray-400">
      Affichage de {{ getItemsFrom }} à {{ getItemsTo }}
      <span v-if="getTotalItems !== 9223372036854775807">({{ getTotalItems }} au total) </span>

      <span v-if="shouldDisplayWithTotalOption">
                &nbsp;&middot;&nbsp;
            </span>

      <span
        v-if="getTotalItems === 9223372036854775807 && shouldDisplayWithTotalOption"
        class="text-sm text-luxcaddy hover:text-luxcaddy-600 border-b border-dashed cursor-pointer"
        @click="itemsWithTotals = 1"
      >
                Afficher le nombre de lignes
            </span>
      <span
        v-if="getTotalItems !== 9223372036854775807 && shouldDisplayWithTotalOption"
        class="text-sm text-luxcaddy hover:text-luxcaddy-600 border-b border-dashed cursor-pointer"
        @click="itemsWithTotals = 0"
      >
                Masquer le nombre de lignes
            </span>
    </div>
  </div>
</template>

<script>
import ButtonElement from "../../../../../elements/buttons/ButtonElement";

export default {
  name: "PaginationPageSizeSelector",
  components: {ButtonElement},
  props: {
    tableIdentifier: {
      type: String,
      required: false
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 50, 100, 500]
    },

    /*
        The following props are only needed when used outside of a ListTable
     */
    perPage: {
      type: Number,
    },
    itemsFrom: {
      type: Number,
    },
    itemsTo: {
      type: Number,
    },
    itemsTotal: {
      type: Number,
    },

  },
  methods: {
    setItemsPerPage(size) {
      if (this.isUsedForListTable) {
        this.itemsPerPage = size;
        return true;
      }

      this.$emit('update:perPage', size);
    }
  },
  computed: {
    itemsWithTotals: {
      get() {
        return this.getPaginationConfig
          ?.withTotals;
      },
      set(value) {
        this.$store.commit('listTable/setItemsWithTotals', {
          tableIdentifier: this.tableIdentifier,
          withTotals: value
        });

        this.$emit('change');
      }
    },

    getItemsPerPage() {
      if (this.isUsedForListTable) {
        return this.itemsPerPage;
      }

      return this.perPage;
    },

    getPaginationConfig() {
      return this.$store.getters['listTable/getPaginationConfigs']
        .find(p => p.tableIdentifier === this.tableIdentifier);
    },

    itemsPerPage: {
      get() {
        return this.getPaginationConfig
          ?.perPage || 1;
      },
      set(value) {
        this.$store.commit('listTable/setItemsPerPage', {
          tableIdentifier: this.tableIdentifier,
          perPage: value
        });
        this.$emit('change');
      }
    },
    getTotalItems() {
      if (!this.isUsedForListTable) {
        return this.itemsTotal;
      }

      return this.getPaginationConfig?.totalItems || 0;
    },
    getItemsFrom() {
      if (!this.isUsedForListTable) {
        return this.itemsFrom;
      }

      return this.getPaginationConfig?.itemsFrom || 0;
    },
    getItemsTo() {
      if (!this.isUsedForListTable) {
        return this.itemsTo;
      }

      return this.getPaginationConfig?.itemsTo || 10;
    },
    shouldDisplayWithTotalOption() {
      // Used outside of a ListTable component
      if (!this.isUsedForListTable) {
        return false;
      }

      // exclude stats listing as there we should always query with totals
      return this.tableIdentifier !== 'productSalesStatsTable' && this.tableIdentifier !== 'supplierSalesStatsTable' && this.tableIdentifier !== 'campaignSalesStatsTable';
    },

    isUsedForListTable() {
      return this.tableIdentifier !== undefined;
    }
  }
}
</script>
