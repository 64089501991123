<template>
  <div>
    <list-table
      :columns="columns"
      :exportButton="true"
      :filters="filters"
      :pagination="true"
      :repository-export-func="repositoryExportFunc"
      export-type="csv"
      :repository-func="repositoryFunc"
      :title="title"
      identifier="productReportsStatecTable"
      show-filters-immediately
    >
    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import {FILTER_DATETIME_RANGE,} from "@/components/listTable/includes/controls/filters/availableFilters";
import ProductReportRepository from "@/repositories/ProductReportRepository";

export default {
  name: "ShowProductReportsStatec",
  components: {ListTable},
  data() {
    const defaultBeginYear = this.$date().startOf('month').format('DD.MM.YYYY HH:mm:ss');
    const defaultEndYear = this.$date().endOf('month').endOf('day').format('DD.MM.YYYY HH:mm:ss');

    return {
      employees: [],

      title: 'Rapport Statec',
      repositoryFunc: ProductReportRepository.getForStatecPaginated,
      repositoryExportFunc: ProductReportRepository.getExportForStatec,
      columns: [
        {
          caption: 'Pays provenance',
          dataField: 'SupplierCountry',
        },
        {
          caption: 'Nature Trans.',
          dataField: 'NatureTrans',
        },
        {
          caption: 'NCReference',
          dataField: 'NCReference',
        },
        {
          caption: 'Poids',
          dataField: 'TotalWeight',
        },
        {
          caption: 'Valeur',
          dataField: 'TotalValue',
        },
        {
          caption: 'Pays origine',
          dataField: 'OriginCountry',
        },
        {
          caption: 'Suppl. units',
          dataField: 'Units',
        },
      ],
      filters: [
        {
          filterKey: 'period',
          caption: 'Date début',
          type: FILTER_DATETIME_RANGE,
          defaultValue: {
            startDate: defaultBeginYear,
            endDate: defaultEndYear,
          }
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>