<template>
    <div>
        <list-table
                :columns="columns"
                :create-button="false"
                :filters="getFilters"
                :pagination="true"
                :repository-func="repositoryFunc"
                :repository-export-func="exportRepositoryFunc"
                :select-values="selectValues"
                :title="title"
                enable-totals
                export-button
                identifier="campaignSalesStatsTable"
                selectable
                show-filters-immediately
                sub-key="items"
        >

            <template v-slot:product-name-template="{ row }">
                <product-name :product="row"/>
            </template>

            <template v-slot:sales-quantity-template="{ row }">
                <div class="w-48 text-black border border-yellow-500 font-bold">
                    <div :style="`width: ${row.quantityPercentage}%`"
                         class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
                        <div class="px-2 text-black">
                            {{ row.totalQuantity }}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:sales-new-members-count-template="{ row }">
                <div class="w-48 text-black border border-yellow-500 font-bold">
                    <div :style="`width: ${row.totalNewMembersUsedCampaignPercentage}%`"
                         class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
                        <div class="px-2 text-black">
                            {{ row.totalNewMembersUsedCampaign }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:sales-members-new-orders-template="{ row }">
                <div class="w-48 text-black border border-yellow-500 font-bold">
                    <div :style="`width: ${row.totalMembersPlacedOrdersPercentage}%`"
                         class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
                        <div class="px-2 text-black">
                            {{ row.totalMembersPlacedOrders }}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:sales-price-no-vat-template="{ row }">
                <div class="w-48 text-black border border-yellow-500 font-bold z-0">
                    <div :style="`width: ${row.priceNoVatPercentage}%`"
                         class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
                        <div class="px-2 text-black">
                            <money-amount
                                :amount="row.priceNoVat"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:sales-price-with-vat-template="{ row }">
                <div class="w-48 text-black border border-yellow-500 font-bold z-0">
                    <div :style="`width: ${row.priceWithVatPercentage}%`"
                         class="h-full bg-yellow-300 top-0 bottom-0 whitespace-nowrap">
                        <div class="px-2 text-black">
                            <money-amount
                                :amount="row.priceWithVat"
                            />
                        </div>
                    </div>
                </div>
            </template>

        </list-table>
    </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import OrderRepository from "@/repositories/OrderRepository";
import {
    FILTER_DATETIME_RANGE, FILTER_SELECT_SINGLE,
} from "@/components/listTable/includes/controls/filters/availableFilters";
import {centsToEuroString} from "@/helpers/mathHelper";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";
import MoneyAmount from "@/components/global/Money/MoneyAmount.vue";

export default {
    name: "CampaignSalesStats",
    components: {
        MoneyAmount,
        ProductName, ListTable
    },
    data() {
        return {
            title: 'Statistiques ventes par campaign',
            selectValues: campaign => {
                return {
                    id: campaign.id,
                    type: campaign.type,
                };
            },
            columns: [
                {
                    caption: '#',
                    width: 80,
                    dataField: 'id',
                },
                {
                    caption: 'Type',
                    dataField: 'type',
                },
                {
                    caption: 'Nom',
                    cellTemplate: 'product-name-template'
                },
                {
                    caption: 'Nombre total',
                    cellTemplate: 'sales-quantity-template',
                    totals: {
                        sum: "totalQuantity",
                        backend: {
                            key: "orderedQuantity"
                        }
                    },
                    sort: {dataField: 'totalQuantity'}

                },
                {
                    caption: 'Nouveaux membres ',
                    cellTemplate: 'sales-new-members-count-template',
                    totals: {
                        sum: "totalNewMembersUsedCampaign",
                        backend: {
                            key: "totalNewMembersUsedCampaign"
                        }
                    },
                    sort: {dataField: 'totalNewMembersUsedCampaign'}

                },
                {
                    caption: 'Membr. comm. suppl.',
                    cellTemplate: 'sales-members-new-orders-template',
                    totals: {
                        sum: "totalMembersPlacedOrders",
                        backend: {
                            key: "totalMembersPlacedOrders"
                        }
                    },
                    sort: {dataField: 'totalMembersPlacedOrders'}

                },
                {
                    caption: 'Total Prix (HTVA)',
                    cellTemplate: 'sales-price-no-vat-template',
                    totals: {
                        sum: "priceNoVat",
                        formatValue: (val) => centsToEuroString(val),
                        backend: {
                            key: "totalPriceNoVat"
                        }
                    },
                    sort: {dataField: 'totalPriceNoVat'}

                },
                {
                    caption: 'Total Prix ',
                    cellTemplate: 'sales-price-with-vat-template',
                    totals: {
                        sum: "priceWithVat",
                        formatValue: (val) => centsToEuroString(val),
                        backend: {
                            key: "totalPriceWithVat"
                        }
                    },
                    sort: {dataField: 'totalPriceWithVat'}

                },
            ],
            repositoryFunc: OrderRepository.stats.getCampaignSalesStats,
            exportRepositoryFunc: OrderRepository.stats.getCampaignSalesStatsExport
        }
    },
    computed: {
        getFilters() {
            let filters = [
                {
                    caption: 'ID(s) ou Nom produit',
                    filterKey: 'idsOrName',
                    forceIsVisible: true,
                    small: true
                },
                {
                    caption: 'Type',
                    filterKey: 'type',
                    filterOptions: this.$luxcaddyConfig('campaigns.availableValueTypes'),
                    type: FILTER_SELECT_SINGLE,
                    forceIsVisible: true,
                },
                {
                    caption: 'Appliqué sur',
                    filterKey: 'appliedOn',
                    filterOptions: this.$luxcaddyConfig('campaigns.availableTypes'),
                    forceIsVisible: true,
                    type: FILTER_SELECT_SINGLE,
                },
                {
                    filterKey: 'createdPeriod',
                    caption: "Commandé entre",
                    type: FILTER_DATETIME_RANGE,
                    defaultValue: {
                        startDate: this.$date().subtract(6, 'months').format('DD.MM.YYYY HH:mm:ss'),
                        endDate: this.$date().format('DD.MM.YYYY HH:mm:ss'),
                    },
                    forceIsVisible: true
                },
            ];

            // remove isDeleted and isPublished filters as confusing and not useful in this context
            filters = filters.filter(f => f.filterKey !== 'isDeleted' && f.filterKey !== 'isPublished');
            // remove isDiscontinued ias we need to overwrite it
            filters = filters.filter(f => f.filterKey !== 'isDiscontinued');

            return filters;
        }
    }
}
</script>

<style scoped>

</style>