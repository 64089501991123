<template>
  <tooltip :text="disabled ? disabledTooltip : tooltip" class="inline-block">
    <button
      :class="[getCustomClasses, inverse ? '' : 'shadow-md']"
      :disabled="disabled"
      :type="[isSubmitButton ? 'submit' : 'button']"
      class="relative cursor-pointer select-none block md:inline-block"
      role="button"
      @click.exact="onClick($event)"
      @click.exact.ctrl="onClickCtrl($event)"
      @mousedown.middle="onClickCtrl($event)"
      @click.meta="onClickCtrl($event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    >
      <div v-if="loading" class="absolute z-2 flex items-center justify-center inset-0">
        <loading-svg-inline/>
      </div>
      <div
        :class="{'opacity-0': loading}"
      >
        <slot></slot>
      </div>
    </button>
  </tooltip>
</template>

<script>
import Tooltip from "../tooltips/Tooltip";
import LoadingSvgInline from "@/components/elements/loading/LoadingSvgInline.vue";

export default {
  name: "ButtonElement",
  components: {LoadingSvgInline, Tooltip},
  props: {
    type: {
      // filterBlue is a special style only used for the listTable filter button.
      default: 'primary',
      validator: val => ['primary', 'success', 'danger', 'warning', 'dark', 'light', 'routeManager', 'filterBlue', 'submit'].includes(val)
    },
    isSubmitButton: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    disabledTooltip: {
      type: String,
      required: false
    },
    inverse: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick(e) {
      if (!this.disabled) {
        this.$emit('click', e);
      }
    },
    onClickCtrl(e) {
      if (!this.disabled) {
        this.$emit('click-ctrl', e);
      }
    }
  },
  computed: {
    /**
     * Returns the css classes that differ based on the given type prop.
     *
     * @returns {string}
     */
    getCustomClasses() {
      let classes = this.disabled ? 'cursor-not-allowed ' : '';
      let size = this.size.toLowerCase();

      switch (size) {
        case "tableaction":
          classes += 'px-0.5 text-lg rounded-md ';
          break;
        case "xxsmall":
          classes += 'py-0.5 px-1 text-xs rounded-md ';
          break;
        case "xsmall":
          classes += 'py-1 px-2 text-xs rounded-md ';
          break;
        case 'small':
          classes += 'py-1 px-3 rounded-md ';
          break;
        case 'medium':
          classes += 'py-2 px-4 rounded-md ';
          break;
        default:
          classes += 'py-3 px-6 rounded-md ';
          break;
      }

      switch (this.type) {
        case 'success':
          classes += this.disabled
            ? 'opacity-50 bg-green-500 text-white'
            : 'bg-green-500 hover:bg-green-600 text-white';
          break;
        case 'filterBlue':
          classes += this.disabled
            ? 'opacity-50 bg-blue-200 text-black'
            : 'bg-blue-200 hover:bg-blue-300 text-black';
          break;
        case 'danger':
          classes += this.disabled
            ? this.inverse
              ? 'opacity-30 text-red-500'
              : 'opacity-50 bg-red-500 text-white'
            : this.inverse
              ? 'text-red-500 hover:text-red-600'
              : 'bg-red-500 hover:bg-red-600 text-white';
          break;
        case 'warning':
          classes += this.disabled
            ? this.inverse
              ? 'opacity-30 text-yellow-500'
              : 'opacity-50 bg-yellow-500 text-white'
            : this.inverse
              ? 'text-yellow-500 hover:text-yellow-600'
              : 'bg-yellow-500 hover:bg-yellow-600 text-white';
          break;
        case 'dark':
          classes += this.disabled ? 'opacity-50 bg-gray-600 text-white' : 'bg-gray-600 hover:bg-gray-700 text-white';
          break;
        case 'light':
          classes += this.disabled ? 'opacity-50 bg-gray-500 text-white' : 'bg-gray-500 hover:bg-gray-600 text-white';
          break;
        case 'routeManager':
          classes += this.disabled ? 'opacity-50 bg-gray-200 text-white' : 'bg-gray-200 hover:bg-gray-300 text-gray-800 border border-gray-300 h-6 shadow-none';
          break;
        default:
          classes += this.disabled
            ? this.inverse
              ? 'opacity-30 luxcaddy'
              : 'opacity-30 bg-luxcaddy-500 text-white'
            : this.inverse
              ? 'text-luxcaddy-500 hover:text-luxcaddy-600'
              : 'bg-luxcaddy-500 hover:bg-luxcaddy-600 text-white';
          break;
      }
      return classes;
    }
  }
}
</script>

