import apiService from './Base/BaseRepository';
import store from "../store/store";

const url = '/orders';

export default {
  /**
   * Fetches all orders.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },

  async getOrderLabelHtml(orderId) {
    return await apiService.get(`${url}/${orderId}/getOrderLabelHtml`);
  },

  /**
   * Fetches all orders.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`);
  },

  /**
   * Fetches the given order.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSingle(orderId, showLoadingIndicator = true) {
    return await apiService
      .get(`${url}/${orderId}`, {showLoadingIndicator});
  },

  async createOrder(payload) {
    return await apiService.post(`${url}/create`, payload);
  },

  async fulfillOrder(orderId, withValidation = false, finalizeInvoice = true) {
    if (withValidation) {
      if (finalizeInvoice) {
        return await apiService.patch(`${url}/${orderId}/fulfillWithValidation`);
      } else {
        return await apiService.patch(`${url}/${orderId}/fulfillWithValidationNoInvoiceFinalize`);
      }
    } else {
      if (finalizeInvoice) {
        return await apiService.patch(`${url}/${orderId}/fulfill`);
      } else {
        return await apiService.patch(`${url}/${orderId}/fulfillNoInvoiceFinalize`);

      }
    }
  },

  /**
   * Get ordering stats
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getOrderStats(groupBy, filters = [], showLoadingIndicator = true) {
    return await apiService
      .setFilters(filters)
      .get(`${url}/stats/items?groupBy=${groupBy}`, {showLoadingIndicator});
  },

  async cancelOrder(orderId) {
    return await apiService
      .patch(`${url}/${orderId}/cancel`);
  },

  async confirmOrder(orderId) {
    return await apiService
      .patch(`${url}/${orderId}/confirm`);
  },

  stats: {
    async getProductSalesStats(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
      return await apiService
        .setPagination(perPage, page, withTotals)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/stats/byProductItems`);
    },
    async getCampaignSalesStats(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0) {
      return await apiService
        .setPagination(perPage, page, withTotals)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/stats/byCampaign`);
    },
    async getProductSalesStatsExport(sorts = [], filters = []) {
      return await apiService
        .setSorts(sorts)
        .setFilters(filters)
        .downloadBlob(`${url}/stats/byProductItems/export`);
    },
    async getCampaignSalesStatsExport(sorts = [], filters = []) {
      return await apiService
        .setSorts(sorts)
        .setFilters(filters)
        .downloadBlob(`${url}/stats/byCampaign/export`);
    },

    async getSupplierSalesStats(perPage = 20, page = 1, sorts = [], filters = []) {
      return await apiService
        .setPagination(perPage, page)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/stats/bySupplier`);
    },


    async getSupplierSalesStatsExport(sorts = [], filters = []) {
      return await apiService
        .setSorts(sorts)
        .setFilters(filters)
        .downloadBlob(`${url}/stats/bySupplier/export`);
    },

    async getSupplierSalesStatsDetails(supplierId, sorts = [], filters = []) {
      return await apiService
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/stats/bySupplier/${supplierId}`);
    },
  },


  /**
   * Removes an item from the order.
   *
   * @param orderId
   * @param itemId
   * @param showLoadingIndicator
   * @param refreshOrder
   * @returns {Promise<*>}
   */
  async removeItem(orderId, itemId, showLoadingIndicator = true, refreshOrder = false) {
    let req = apiService.delete(`${url}/${orderId}/removeItem`, {
      data: {
        itemId: itemId
      },
      showLoadingIndicator
    });


    if (refreshOrder) {
      req.finally(() => {
        store.dispatch('order/refreshOrder', false);
      })
    }

    return req;
  },

  /**
   * Changes the customerMessage on an order.
   *
   * @param orderId
   * @param message
   * @returns {Promise<*>}
   */
  async editCustomerMessage(orderId, message) {
    return await apiService.patch(`${url}/${orderId}/editCustomerMessage`, {
      message: message
    });
  },

  /**
   * Edits the given itemId of order orderId
   *
   * @param orderId
   * @param itemId
   * @param payload
   * @param withLoadingIndicator
   * @param refreshOrder
   * @return {Promise<AxiosResponse<any>>}
   */
  async editItem(orderId, itemId, payload, withLoadingIndicator = true, refreshOrder = false) {
    let req = apiService.patch(`${url}/${orderId}/editItem`, {
      itemId,
      ...payload
    }, {
      showLoadingIndicator: withLoadingIndicator
    });

    if (refreshOrder) {
      req.finally(() => {
        store.dispatch('order/refreshOrder', false);
      })
    }

    return req;
  },

  /**
   *
   * @param orderId
   * @param itemId
   * @param toBePackedQuantity
   * @param reference
   * @param substituteReferencedItem
   * @param useBuyingPriceWithAddOn
   * @param withLoadingIndicator
   * @param refreshOrder
   * @return {Promise<AxiosResponse<any>>}
   */
  async addItem(orderId, itemId, toBePackedQuantity, reference = null, substituteReferencedItem = false, useBuyingPriceWithAddOn = false,  withLoadingIndicator = true, refreshOrder = false) {
    let payload = {
      productId: itemId,
      toBePackedQuantity: toBePackedQuantity,
    };

    if (reference) {
      payload.reference = reference;
      payload.substituteReferencedItem = substituteReferencedItem;
    }

    let route = 'addProductItem';
    if(useBuyingPriceWithAddOn){
      payload.useBuyingPriceWithAddOn = useBuyingPriceWithAddOn;
      route = 'addProductItemWithAddOns';
    }

    let req = apiService.post(`${url}/${orderId}/${route}`, payload, {
      showLoadingIndicator: withLoadingIndicator
    });



    if (refreshOrder) {
      req.then(() => {
        store.dispatch('order/refreshOrder', false);
      })
    }

    return req;
  },

  /**
   *
   * @param orderId
   * @param payload
   * @param withLoadingIndicator
   * @param refreshOrder
   * @return {Promise<AxiosResponse<any>>}
   */
  async addCustomItem(orderId, payload, withLoadingIndicator = true, refreshOrder = false) {
    let req = apiService.post(`${url}/${orderId}/addCustomItem`, payload, {
      showLoadingIndicator: withLoadingIndicator
    });

    if (refreshOrder) {
      req.then(() => {
        store.dispatch('order/refreshOrder', false);
      });
    }

    return req;
  },

  async addFeeItem(orderId, payload, withLoadingIndicator = true, refreshOrder = false) {
    let req = apiService.post(`${url}/${orderId}/addFeeItem`, payload, {
      showLoadingIndicator: withLoadingIndicator
    });

    if (refreshOrder) {
      req.then(() => {
        store.dispatch('order/refreshOrder', false);
      });
    }

    return req;
  },

  async addPackageItem(orderId, payload, withLoadingIndicator = true, refreshOrder = false) {
    let req = apiService.post(`${url}/${orderId}/addPackageItem`, payload, {
      showLoadingIndicator: withLoadingIndicator
    });

    if (refreshOrder) {
      req.then(() => {
        store.dispatch('order/refreshOrder', false);
      });
    }

    return req;
  },


  /**
   * Changes the delivery contact of an order.
   *
   * @param orderId
   * @param contactId
   * @return {Promise<AxiosResponse<any>>}
   */
  async changeDeliveryContact(orderId, contactId) {
    return await apiService.patch(`${url}/${orderId}/changeDeliveryContact`, {
      deliveryContactId: contactId
    });
  },

  /**
   * Changes the delivery timeslot of an order.
   *
   * @param orderId
   * @param timeslotId
   * @return {Promise<AxiosResponse<any>>}
   */
  async changeDeliveryTimeSlot(orderId, timeslotId) {
    return await apiService.patch(`${url}/${orderId}/changeDeliveryTimeslot`, {
      deliveryTimeslotId: timeslotId
    });
  },


  /**
   * Returns the zone & available timeslots.
   *
   * @returns {Promise<*>}
   * @param memberId
   * @param deliveryContactId
   * @param deliveryTimeSlotId
   * @param packageOptions
   */
  async convertFromCart(memberId, deliveryContactId, deliveryTimeSlotId, packageOptions, shouldCreateInvoice) {
    return await apiService.post(`${url}/convertCart`, {
      memberId, deliveryContactId, deliveryTimeSlotId, packageOptions, shouldCreateInvoice
    });
  },

  fulfilment: {
    async getFulfilmentList(perPage = 999, page = 1, sorts = [], filters = []) {
      return await apiService
        .setPagination(perPage, page)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/fulfilment`);
    },

    /**
     * Returns the fulfilment details of a given order.
     *
     * @param orderId
     * @returns {Promise<*>}
     */
    async getOrderFulfilmentDetails(orderId, filters = []) {
      return await apiService
        .setFilters(filters)
        .get(`${url}/${orderId}/fulfilmentDetails`);
    },

    async addItemPackedQuantity(orderId, itemId, packedQuantity, packedWeight) {
      return await apiService
        .patch(`${url}/${orderId}/addItemPackedQuantity`, {
          itemId,
          packedQuantity,
          packedWeight
        }, {
          showLoadingIndicator: false
        });
    },

    async resetItemPackedQuantity(orderId, itemId) {
      return await apiService
        .patch(`${url}/${orderId}/resetItemPackedQuantity`, {
          itemId
        }, {
          showLoadingIndicator: false
        });
    },

    async addItemPackedQuantityByBarcode(orderId, barCode, packedQuantity) {
      return await apiService
        .patch(`${url}/${orderId}/addItemPackedQuantityByBarcode`, {
          barcode: barCode,
          packedQuantity
        });
    },

    /**
     * Returns the days in the range of the next 7 days that have at least one order.
     * @returns {Promise<*>}
     */
    async getNextWeekdaysWithOrderCount() {
      return await apiService
        .get(`${url}/count/week-days`);
    },
  },

  instructions: {
    async getPaginated(orderId, perPage = 20, page = 1, sorts = [], filters = []) {
      return await apiService
        .setPagination(perPage, page)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/${orderId}/instruction`);
    },

    async getSingle(orderId, instructionId) {
      return await apiService.get(`${url}/${orderId}/instruction/${instructionId}`);
    },

    async create(orderId, payload) {
      return await apiService.post(`${url}/${orderId}/instruction`, payload);
    },

    async update(orderId, instructionId, payload) {
      return await apiService.patch(`${url}/${orderId}/instruction/${instructionId}`, payload);
    },

    async delete(orderId, instructionId) {
      return await apiService.delete(`${url}/${orderId}/instruction/${instructionId}`);
    },
  },

  batchActions: {
    async markOrdersAsFulfilled(orders) {
      return await apiService.patch(`${url}/batchAction`, {
        jobAction: "markAsFulfilled",
        items: orders
      });
    },

    async substituteProductByProduct(items, {productToBeSubstituted, substituteByProduct}) {
      return await apiService.patch(`${url}/batchAction`, {
        jobAction: "substituteProductByProduct",
        items: items,
        params: {
          productToBeSubstituted: productToBeSubstituted,
          substituteByProduct: substituteByProduct
        }
      });
    },
    async setProductQuantityToZero(items, {productToBeSetToZero}) {
      return await apiService.patch(`${url}/batchAction`, {
        jobAction: "setProductQuantityToZero",
        items: items,
        params: {
          productToBeSetToZero: productToBeSetToZero,
        }
      });
    },

    async substituteProductByCustomItem(items, {productToBeSubstituted, name, price, vat}) {
      return await apiService.patch(`${url}/batchAction`, {
        jobAction: "substituteProductByCustomItem",
        items: items,
        params: {
          productToBeSubstituted: productToBeSubstituted,
          name: name,
          price: price,
          vat: vat,
        }
      });
    }
  }
}
