
import CustomTable from "@/components/elements/tables/CustomTable.vue";
import TableRow from "../elements/tables/TableRow.vue";
import TableDataCell from "../elements/tables/TableDataCell.vue";
import ButtonElement from "../elements/buttons/ButtonElement.vue";
import PageTitle from "../elements/pages/PageTitle.vue";
import ListTablePagination from "./includes/controls/pagination/ListTablePagination.vue";
import Tooltip from "../elements/tooltips/Tooltip.vue";
import ListTableColumnToggleButton from "./includes/controls/columnToggle/ListTableColumnToggleButton.vue";
import ListTableFiltersList from "./includes/controls/filters/ListTableFiltersList.vue";
import ListTableBatchActionsButton from "@/components/listTable/includes/batch/BatchActionsButton.vue";
import ListTableTableRow from "@/components/listTable/includes/table/ListTableTableRow.vue";
import {exportResponse} from '@/helpers/exportHelper.js';
import ListTableTotalsRow from "@/components/listTable/includes/table/ListTableTotalsRow.vue";
import newTabMixin from "@/mixins/newTabMixin";
import {checkIfObjectsMatch} from "@/helpers/objectHelper";
import Vue, {PropType} from "vue";
import {Column} from "./ListTableTypes";
import {eventBus} from "@/eventBus";

export default Vue.extend({
  name: "ListTable",
  components: {
    ListTableTotalsRow,
    ListTableTableRow,
    ListTableBatchActionsButton,
    ListTableFiltersList,
    ListTableColumnToggleButton,
    Tooltip,
    ListTablePagination,
    PageTitle,
    ButtonElement,
    TableDataCell,
    TableRow,
    CustomTable,
  },
  mixins: [newTabMixin],
  props: {
    /**
     * Used to save Filter, Sort etc in the store.
     */
    identifier: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    columns: {
      type: Array as PropType<Array<Column>>,
      required: true
    },
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    // Filters that are applied automatically and can not be changed.
    forceFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    repositoryFunc: {
      type: Function,
      required: false
    },
    // Passes an array of data that should be used.
    // if this is passed, repositoryFunc is unnecessary.
    sentTableRows: {
      type: Array,
      required: false,
    },
    repositoryExportFunc: {
      type: Function,
      required: false
    },
    exportType: {
      type: String,
      default: "excel"
    },
    createRoute: {
      type: Object,
    },
    createEvent: {
      type: Boolean,
      default: false
    },
    exportButton: {
      type: Boolean,
      default: false
    },
    showActionButtons: {
      type: Boolean,
      default: true
    },
    /**
     * === [ Pagination ]
     *
     * Only works if passed Repository Function supports it.
     */
    pagination: {
      type: Boolean,
      default: false
    },

    /*
        When enabled, there will be checkboxes to select each line
     */
    selectable: {
      type: Boolean,
      default: false
    },
    /*
        The key which will be used to trigger batch actions.
        ["id"] or multiple values:
        ["id", "productId]"
     */
    selectValues: {
      type: [Array, Function],
    },
    batchActions: {
      type: Array,
      default: () => []
    },
    refreshAfterBatchAction: {
      type: Boolean,
      default: true
    },

    // Sub-Object representing the rows. If undefined will be res.data.data, else res.data.data[subKey]
    subKey: {
      type: String,
      default: undefined
    },

    showRefreshButton: {
      type: Boolean,
      default: true
    },

    enableTotals: {
      type: Boolean,
      default: false
    },

    disableClearBatchErrorsOnMount: {
      type: Boolean,
      default: false
    },

    selectValuesMap: {
      type: Object,
      default: null
    },

    /**
     * Adds one row after each usual row that can be customized using the extra-row template
     */
    enableExtraRow: {
      type: Boolean,
      default: false
    },

    extraRowSettings: {
      type: Object,
      default: () => ({
        showOnlyForSelectionAfterButtonClick: true,
        button: {
          text: 'Activer ligne supplémentaire',
          icon: 'check'
        }
      })
    },

    enableExtraButton: {
      type: Boolean,
      default: false
    },

    extraButton: {
      type: Object,
      default: () => ({
        action: false,
        button: {
          text: 'Action',
          icon: 'check'
        }
      })
    },

  },
  beforeDestroy() {
    if (this.pagination) {
      this.$store.commit('listTable/setCurrentPage', 1);
    }

    if (!this.disableClearBatchErrorsOnMount) {
      this.$store.commit('listTable/clearBatchErrors');
    }
    this.$store.commit('listTable/clearSelectedRows');
  },
  created() {
    this.$store.commit('listTable/resetBatchReturnedData');
    if (!this.disableClearBatchErrorsOnMount) {
      this.$store.commit('listTable/clearBatchErrors');
    }
  },
  mounted() {
    // Disables selecting text only while shift is held down.
    // Needed so that no text will be selected while using the shift-select system
    // to select multiple rows at once.
    // From https://stackoverflow.com/a/54101500/6578746
    if (this.selectable) {
      ["keyup", "keydown"].forEach((event) => {
        window.addEventListener(event, (e) => {
          document.onselectstart = function () {
            return !(e.key === "Shift" && e.shiftKey);
          }
        });
      });
    }


    this.$nextTick(() => {
      this.getData().then(() => this.isFullyMounted = true);
    });
  },
  data: () => ({
    tableRows: [],
    tableTotals: null, // Totals returned from the backend
    isFullyMounted: false,

    // Used for Shift Clicking to select multiple rows
    lastSelectedOrUnselectedRow: null,

    // The rows for which extra row is shown
    extraRowShownForRows: [],
  }),
  watch: {
    getItemsPerPage: function () {
      this.getData(true);
    },
    getItemsWithTotals: function () {
      if (!this.isFullyMounted) {
        return false;
      }
      this.getData();
    },
    getCurrentPage: function () {
      this.getData();
    },
    getSorts: function () {
      if (!this.isFullyMounted) {
        return false;
      }

      this.getData();
    },
    // React to Filter query changes
    '$route.fullPath': function () {
      this.$nextTick(() => this.getData());
    }
  },
  methods: {

    // Allows to update a value from a single row
    // identifierField = used to determine which row (usually "id")
    // identifierValue = used to determine the value to identiy
    //
    // keyToUpdate = ex. "title",
    // valueToUpdate = what keyToUpdate will be set to
    updateTableRowValue(identifierField, identifierValue, keyToUpdate, valueToUpdate) {
      let row = this.tableRows.find(t => t[identifierField] === identifierValue);
      if (row) {
        this.$set(row, keyToUpdate, valueToUpdate);
      }
    },

    /**
     * Unselects all rows & clears all batch errors when
     * the page, or size of items per page is changed.
     */
    onPaginationChange() {
      this.unselectAllRows();
      this.$store.commit('listTable/clearBatchErrors');
    },


    onReloadClick() {
      if (this.sentTableRows) {
        return this.$emit('reload');
      }

      return this.getData();
    },

    selectAllRows() {
      if (!this.selectable)
        return false;

      this.tableRows.forEach((row) => {
        const value = this.selectValues(row);
        this.$store.commit('listTable/selectRow', value);
      })
    },
    unselectAllRows() {
      if (!this.selectable)
        return false;

      this.$store.commit('listTable/clearAllSelectedRows');
    },


    /**
     * Marks the row
     * @param $event
     * @param row
     * @returns {boolean}
     */
    setLastSelectedOrUnselectedRow($event, row) {
      if (!this.selectable || ($event !== null && $event.shiftKey === true)) {
        return false;
      }

      this.lastSelectedOrUnselectedRow = row;
    },

    /**
     * The user has selected at least one row or unselected at least one row
     * prior to shift clicking $clickedRow.
     *
     * If so, all rows in between the last (un)selected and $clickedRow will also be (un)selected.
     *
     * @param clickedRow
     * @returns {boolean}
     */
    selectTableRowsBetween(clickedRow) {
      if (!this.selectable || this.lastSelectedOrUnselectedRow === null)
        return false;

      // Determine if previously clicked row is selected or unselected
      // and decide based on that what to do with all the rows between.
      const clickedRowValue = this.selectValues(this.lastSelectedOrUnselectedRow);
      let action = this.$store.getters["listTable/isRowSelected"](clickedRowValue)
        ? 'select'
        : 'unselect';

      // Loop through ALL rows,
      const rowsWithSelectValues = this.tableRows.map((tableRow) => {
        const value = this.selectValues(tableRow);
        return {
          rowData: tableRow,
          selectValuesResult: value,
          isSelected: this.$store.getters["listTable/isRowSelected"](value),
          clicked: checkIfObjectsMatch(this.selectValues(clickedRow), value),
          lastClicked: checkIfObjectsMatch(value, clickedRowValue)
        };
      });

      // Get all formatted rows that will be affected from shift clicking
      // So all rows between the last clicked and the currently clicked.
      const lastRowIndex = rowsWithSelectValues
        .filter(rowObject => rowObject.selectValuesResult !== clickedRowValue)
        .findIndex(rowObject => {
          return rowObject.lastClicked === true;
        });
      const clickedRowIndex = rowsWithSelectValues.findIndex(rowObject => rowObject.clicked);


      // Get all rows between (including last selected & clicked)
      rowsWithSelectValues.filter((rowObject, i) => {
        return lastRowIndex > clickedRowIndex
          ? i >= clickedRowIndex && i <= lastRowIndex
          : i <= clickedRowIndex && i >= lastRowIndex;
      }).forEach(rowObject => {
        if (action === "select" && !rowObject.isSelected) {
          this.$store.commit('listTable/selectRow', rowObject.selectValuesResult);
        } else if (action === "unselect" && rowObject.isSelected) {
          this.$store.commit('listTable/unselectRow', rowObject.selectValuesResult);
        }
      });

      // Mark this now as last selected row.
      this.setLastSelectedOrUnselectedRow(null, clickedRow);
    },

    exportData() {
      return this.repositoryExportFunc(
        this.getSorts,
        this.getFilters
      ).then(response => {
        exportResponse(response, this.title.toLowerCase().replaceAll(' ', '-') + '-export', this.exportType);
      });
    },

    getData(resetCurrentPage = false) {
      if (this.sentTableRows) {
        this.tableRows = this.sentTableRows;
        this.$emit('loaded', this.tableRows);
        this.unselectHiddenSelectedRows();
        return Promise.resolve(this.tableRows);
      }

      if (this.pagination) {
        return this.repositoryFunc(
          this.getItemsPerPage,
          this.getCurrentPage,
          this.getSorts,
          this.getFilters,
          this.getItemsWithTotals
        ).then(res => {
          this.tableRows = this.subKey
            ? res.data.data[this.subKey]
            : res.data.data;

          if (res.data.data.totals !== undefined) {
            this.tableTotals = res.data.data.totals;
          }

          this.$emit('loaded', res.data.data);

          this.$store.commit('listTable/setStateFromResponse', {
            tableIdentifier: this.getTableId,
            responseMeta: res.data.meta
          });

          if (resetCurrentPage) {
            this.$store.commit('listTable/setCurrentPage', {
              tableIdentifier: this.getTableId,
              page: 1
            });
          }
        }).finally(() => {
          this.unselectHiddenSelectedRows();
        });
      }

      return this.repositoryFunc(
        this.getSorts,
        this.getFilters
      ).then(res => {
        this.tableRows = this.subKey
          ? res.data.data[this.subKey]
          : res.data.data;

        this.$emit('loaded', this.tableRows);
      }).finally(() => {
        this.unselectHiddenSelectedRows();
      });
    },
    resetFilterValues() {
      this.$store.commit('listTable/filters/resetFilterValues', this.getTableId);
      this.getData();
      this.$emit('filtersReset');
    },
    applyFilters() {
      this.getData(true);
      this.$emit('filtersApplied');
    },
    refresh() {
      return this.getData();
    },

    // If executed, the extra row will be shown below all rows
    // that are currently selected.
    showExtraRowForSelection() {
      this.extraRowShownForRows = JSON.parse(JSON.stringify(this.$store.getters["listTable/getSelectedRows"]));
    },

    isExtraRowShownForRow(row) {
      let isExtraRowShown = false;
      this.extraRowShownForRows.forEach(cycledRow => {
        if (checkIfObjectsMatch(cycledRow, this.selectValues(row))) {
          isExtraRowShown = true;
        }
      })
      return isExtraRowShown;
    },

    // Removes Rows from the selections that are now hidden.
    // This happens for example if you have a filter set, and after executing a batch action
    // some of the selected rows become not shown, but are still selected.
    unselectHiddenSelectedRows() {
      if (!this.selectable) {
        return false;
      }

      // Need to remove reference/reactivity, otherwise the forEach will not execute as it should.
      const selectedRows = JSON.parse(JSON.stringify(this.$store.getters["listTable/getSelectedRows"]));
      const tableRows = this.tableRows.map(row => this.selectValues(row));

      selectedRows.forEach(selectedRow => {
        let isSelectedRowStillVisible = false;

        tableRows.forEach(tableRow => {
          if (checkIfObjectsMatch(selectedRow, tableRow)) {
            isSelectedRowStillVisible = true;
          }
        });

        if (!isSelectedRowStillVisible) {
          this.$store.commit('listTable/unselectRow', selectedRow);
        }
      })
    },

    onBatchActionExecuted() {
      this.$emit('batch-executed');
      this.refresh().then(() => {
        this.unselectHiddenSelectedRows();
      });
    },
    resetSort() {
      this.$store.commit('listTable/clearTableSort', this.getTableId);
      this.$refs.customTable.setDefaultSorts();
    },

    navigateToCreateRoute(newTab = false) {
      if (this.createEvent) {
        return this.$emit('create');
      }

      return newTab
        ? this.openRouteInNewTab(this.createRoute)
        : this.$router.push(this.createRoute);
    },

    executeExtraButtonAction() {
      if (this.extraButton.action) {
        return eventBus.$emit('update-cache');
      }
    },
  },
  computed: {
    getTableId() {
      return this.identifier;
    },

    getVisibleColumns() {
      return this.columns
        // Hide Columns that are toggleable and have visible === false
        .filter(c => {
          if (!this.canAnyColumnBeHidden) {
            return true
          }

          return !this.toggledColumns.find(toggledColumn => {
            return toggledColumn.name === c.caption && !(toggledColumn.visible);
          });
        })
    },

    getTableHeadings() {
      let columns = this.getVisibleColumns
        .map(c => {
          // Right align numeric values, euro amounts ans percentage values
          let align = 'left';
          if (['euro', 'percentage', 'numeric'].includes(c.dataType)) {
            align = 'right';
          }

          return {
            label: c.caption,
            dataField: c.dataField,
            dataType: c.dataType,
            width: c.width,
            calculateCellValue: c.calculateCellValue,
            cellTemplate: c.cellTemplate,
            buttons: c.buttons,
            sort: c.sort,
            visible: c.visible,
            canBreakLine: c.canBreakLine,
            additionalClasses: c.additionalClasses,
            align: align
          }
        });

      // Add a column at the beginning for the Selection
      if (this.selectable) {
        columns.unshift({
          selectbox: true,
          width: 40
        });
      }

      return columns;
    },

    getSorts() {
      return this.$store.getters['listTable/getSorts']
        .filter(s => s.tableIdentifier === this.getTableId)
        .map(s => ({
          key: s.key,
          sort: s.sort,
          dataField: s.dataField
        }));
    },

    getFilters() {
      let filters = [];

      if (this.forceFilters.length) {
        filters = this.forceFilters;
      }

      return filters.concat(this.getFiltersFromStore);
    },

    getFiltersFromStore() {
      return this.$store
        .getters['listTable/filters/getFilters']
        .filter(f => f.tableIdentifier === this.getTableId)
        .filter(f => {
          return f.filterValue !== "" && f.filterValue !== [] && f.filterValue !== null && f.filterValue !== undefined;
        });
    },

    getPaginationConfig() {
      return this
        .$store
        .getters['listTable/getPaginationConfigs']
        .find(p => p.tableIdentifier === this.getTableId)
    },

    /**
     * Return the number of items shown per page.
     */
    getItemsPerPage() {
      return this
        .getPaginationConfig
        ?.perPage || 20;
    },
    getItemsWithTotals() {
      return this
        .getPaginationConfig
        ?.withTotals || 0;
    },
    /**
     * Returns the current page
     */
    getCurrentPage() {
      return this
        .getPaginationConfig
        ?.currentPage || 1;
    },

    /**
     * Columns that will be saved to the store and marked as toggleAble
     * @return {*[]}
     */
    toggleableColumns() {
      return this
        .columns
        .filter(c => c.visible !== undefined);
    },
    /**
     * Toggled columns - The columns that can be toggled with their current state.
     * Visible or not visible.
     *
     * @return {[]|*}
     */
    toggledColumns() {
      if (!this.canAnyColumnBeHidden)
        return [];

      return this.$store.getters['listTable/getToggleAbleColumns']
      // .filter(tc => tc.tableIdentifier === this.getTableId);
    },
    canAnyColumnBeHidden() {
      return this.toggleableColumns.length > 0;
    },
    isAnyColumnUsingSort() {
      return this.columns.filter(c => c.sort !== undefined).length > 0;
    },
    isLoading() {
      return this.$store.getters['loading/isRunningRequest'];
    },

    getScopedSlotsWithoutExtraName() {
      let slots = {...this.$scopedSlots};
      delete slots["extra-row-template"];
      return slots;
    },
    getExtraRowScopedSlot() {
      if ("extra-row-template" in this.$scopedSlots) {
        return this.$scopedSlots["extra-row-template"];
      }
      return null;
    }
  }
})
