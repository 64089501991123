<template>
  <VTooltip :disabled="!text || !enabled">
    <slot></slot>

    <template #popper>
      {{ text }}
    </template>

  </VTooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    text: {
      type: String,
      required: false
    },
    enabled: {
      type: Boolean,
      default: true
    }
  }
}
</script>

