import apiService from "@/repositories/Base/BaseRepository";

const url = '/my';

export default {
  tasks: {
    async fetchMyUnfinishedTasks() {
      return await apiService
        .get(`${url}/tasks/unfinished`);
    },

    async markTaskDone(taskId) {
      return await apiService
        .patch(`${url}/tasks/${taskId}/markFinished`);
    }
  },
  notifications: {
    async markAllAsRead() {
      return await apiService.patch(`${url}/notifications/markAllRead`);
    }
  }
}
